/* eslint-disable react/display-name */
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const projects = [
  {
    name: "Kelawar",
    description: () => (
      <div>
        Kelawar is a system that aims to collect data of customers visiting a shop. 
        The owner can access multiple stats about who is visiting and therefore improve their business.
        Get more info at{' '}
        <a className="underline" href="https://kelawar.com" target="_blank" rel="noreferrer">
          kelawar.com
        </a>
      </div>
    )
  },
  {
    name: "Sanmarcossierras.info",
    description: () => (
      <div>
        Website I built for a small town located in Cordoba, Argentina. 
        The website shows the best things to do in San Marcos Sierras and it&apos;s surroundings 
        plus a list of places to stay during the tourists visits. {' '}
        <a className="underline" href="https://sanmarcossierras.info"  target="_blank" rel="noreferrer">
          sanmarcossierras.info
        </a>
      </div>
    )
  }
];

function ProjectsPage() {
  return (
    <Layout>
      <SEO
        keywords={["Marcos", "Comelli", "Frontend", "Software", "Developer"]}
        title="Projects"
      />

      <section>
        <h2 className="inline-block p-3 mb-6 text-xl md:text-4xl font-bold bg-background-secondary">
          Projects 🛠
        </h2>
        <p className="text-lg mb-4 py-3">
          I finished my studies on computer engineering in 2018 and since then I&apos;ve been constantly learning new technologies. 
          Every now and then I come up with some product ideas that I want to implement on my own or with some friends.
          In this section I document some of them.
        </p>

        <div>
          {projects.map(p => (
            <div key={p.name} className="mb-4">
              <h3><span className="font-bold bg-orange-200 rounded-lg p-1 text-copy-secondary">{p.name}</span></h3>
              <div className="py-2">{p.description()}</div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export default ProjectsPage;
